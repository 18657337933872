import React from 'react';
import CategoryItem from './CategoryItem';
import { ContentBlockMetaData } from '../content/ContentBlocks';
import { isArray, isEmpty } from 'lodash';

type Props = {
  blocks: ContentBlockMetaData | ContentBlockMetaData[];
};

const Categories = (props: Props) => {
  if (isEmpty(props.blocks) || !isArray(props.blocks)) {
    return <></>;
  }
  return (
    <>
      <div className="mx-4 md:mx-24 md:my-8 ">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-9 container ">
          {props.blocks.map((category: ContentBlockMetaData, i) => {
            return (
              <CategoryItem
                key={i}
                name={category['AU'].title}
                slug={category['AU'].redirectUrl}
                img={category['AU'].imageUrl}
                alt={
                  category['AU'].alt ? category['AU'].alt : category['AU'].title
                }
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Categories;
