import Link from 'next/link';
import React from 'react';
import Image from '../../components/common/Image';
import getImgUrl from '../../utils/getImageUrl';

type Props = {
  name: string;
  slug: string;
  img: string;
  alt: string;
};

const CategoryItem = ({ name, slug, img, alt }: Props) => {
  const isNew = name.includes('{{isNew}}');
  let cleanedName = name;
  if (isNew) {
    cleanedName = cleanedName.replace('{{isNew}}', '').trim();
  }
  return (
    <div>
      <Link href={slug} prefetch={false}>
        <div
          className={`aspect-1 w-full max-w-full max-h-[252px] overflow-hidden`}
        >
          <Image
            width={354}
            height={252}
            priority
            blur={false}
            src={getImgUrl(img)}
            alt={alt}
            className="w-full h-full object-center object-cover"
          />
        </div>
        <p className="text-center text-black text-sm font-medium mt-3 grid-row tracking-[0.02em] uppercase">
          {cleanedName}
          {isNew && (
            <span className="inline-flex items-center rounded-full bg-brand-primary px-3 py-0.5 text-sm font-medium text-white ml-1">
              New
            </span>
          )}
        </p>
      </Link>
    </div>
  );
};

export default CategoryItem;
